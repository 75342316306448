<div class="dropdown d-flex" (click)="toggleStyleDisplay(style)" (clickOutside)="style.display = 'none'">
  <div
    class="user-profile"
  >
    <ng-container *ngIf="!profPic">
      <div *ngIf="!!acronym" class="txt--acronym">{{ acronym }}</div>
      <img
        *ngIf="!acronym"
        class="img-fluid default"
        src="assets/img/default-user.png"
      />
    </ng-container>
    <img
      *ngIf="profPic"
      class="img-fluid"
      [src]="profPic"
    />
  </div>
  <span class="profile-title">
    {{ "common.profile._" | translate }}
  </span>
  <app-panel class="dropdown-content" [class]="lang.isRTL ? 'rtl-dropdown-content' : ''" [ngStyle]="style"></app-panel>
</div>
